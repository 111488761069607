import React, {Component} from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

class PageTemplate extends Component{
    render(){
        const currentPage = this.props.data.wordpressPage
        return (
            <Layout>
                <div className="row">
                    <div className="col-md-12">
                        <h1 dangerouslySetInnerHTML={{__html: currentPage.title}}/>
                        <div dangerouslySetInnerHTML={{__html: currentPage.content}}/>
                    </div>
                </div>
            </Layout>
        )
    }
}
export default PageTemplate

export const query = graphql`
    query currentPageQuery($id: String!) {
        wordpressPage(id: { eq: $id }) {
            title
            content
            slug
            id
            date(formatString: "MMMM DD, YYYY")
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`